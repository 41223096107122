import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "languages-for-engineering-not-for-coding",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#languages-for-engineering-not-for-coding",
        "aria-label": "languages for engineering not for coding permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Languages for Engineering, not for Coding`}</h1>
    <p>{`There are two kinds of programming languages.`}</p>
    <p><strong parentName="p">{`Languages for Coding`}</strong>{` are like C, Python, and JavaScript.`}</p>
    <p>{`A human writes code.`}</p>
    <p>{`Then the language tooling comes in. The code is then compiled, ahead of time
or Just in Time, or maybe it’s not compiled but interpreted instead. Then
it’s executed. End of story.`}</p>
    <p>{`Why did the human write the code? Is it the code that the human wanted to
write? How is the code documented?`}</p>
    <p>{`I don’t think coding is enough, and modern languages don’t stop on this.`}</p>
    <p>{`Take a look at TypeScript which AST has nodes for JSDoc comments.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/15332326/101243732-7e199480-3702-11eb-9d7e-d25e44e75817.png",
        "alt": null
      }}></img></p>
    <p>{`TypeScript cares about your comments because they need to appear in
tooltips.`}</p>
    <p>{`Even more modern, Unison goes a few steps forwards and treats documentation
as first-class value in the language and documents it in the
`}<a parentName="p" {...{
        "href": "https://www.unisonweb.org/docs/language-reference/#documentation-literals"
      }}>{`language reference`}</a>{`.
Test tooling is also a builtin there.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      